.loadText {
    margin-top: 15vh;
    margin-bottom: 15vh;
    font-weight: 1000;
    color: #ffffff;
    font-size: 4vw;
    text-align: center;
  }

  .loader {
    display: inline-flex;
    margin: 0 auto;
    height: 100px;
    font-size: 10px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translateY(-50%) translateX(-50%);
    
    > div {
      height: 100%;
      width: 10px;
      display: inline-flex;
      justify-content: center;
      /* flex-direction: column; */
      /* display: inline-block; */
      /* float: left; */
      margin-left: 2px;
      -webkit-animation: delay 0.8s infinite ease-in-out;
      animation: delay 0.8s infinite ease-in-out;
    }
    
    .bar1{
      background-color: #cd1344;
    }
    .bar2{
      background-color: #fa6919;
      -webkit-animation-delay: -0.7s;
      animation-delay: -0.7s;
    }
    .bar3{
      background-color: #fcca05;
      -webkit-animation-delay: -0.6s;
      animation-delay: -0.6s;
    }
    .bar4{
      background-color: #4be09d;
      -webkit-animation-delay: -0.5s;
      animation-delay: -0.5s;
    }
    .bar5{
      background-color: #39bbf5;
      -webkit-animation-delay: -0.4s;
      animation-delay: -0.4s;
    }
    .bar6{
      background-color: #681d75 ;
      -webkit-animation-delay: -0.3s;
      animation-delay: -0.3s;
    }
  }
  
  
  @-webkit-keyframes delay {
    0%, 40%, 100% { -webkit-transform: scaleY(0.05) }  
    20% { -webkit-transform: scaleY(1.0) }
  }
  
  @keyframes delay {
    0%, 40%, 100% { 
      transform: scaleY(0.05);
      -webkit-transform: scaleY(0.05);
    }  20% { 
      transform: scaleY(1.0);
      -webkit-transform: scaleY(1.0);
    }
  }
  

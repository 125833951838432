.componentContainer {
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 5vh;
  margin-bottom: 5vh;
  padding: 1rem;
  /* border-radius: 30px; */
  background: linear-gradient(to right bottom,
  #b00634,
  #fa6919,
  #d0c500,
  #0bb42f,
  #39bbf5,
  #681d75 
    );
}


.contactBackground{
  display: flex !important;
  flex-direction: column;
  align-items: center;
  background-color: #4e565f;
  width: 100%;
  height: 100%;
  /* border-radius: 20px; */
  padding: 3%;
}

.contactTitle{
    color: white;
    text-shadow: 3px 3px 5px #20201f;
    font-size: 3.5rem;
    font-weight: 700;
    margin-top: 1%;
}

.contactText {
    color: white;
    text-shadow: 3px 3px 5px #20201f;
    font-size: 2rem;
    font-weight: 700;
    margin-top: 1%;
    }
  
  .conLabel {
    margin-top: 1%;
    margin-bottom: -1.5vh;
    color: white;
    font-size: 1.4rem;
  }
  
  input[type="text"],
  input[type="email"],
  select {
    font-size: 1.4rem;
    width: 100%;
    padding: 12px 20px;
    margin: 2px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    box-shadow: 3px 3px 5px #20201f;
  }
  
  textarea {
    font-size: 1.4rem;
    width: 100%;
    height: 150px;
    padding: 12px 20px;
    margin: 2px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    box-shadow: 3px 3px 5px #20201f;
  }
  input[type="text"]:hover,
  input[type="email"]:hover,
  textarea:hover {
    transform: scale(1.02);
  }
  
  input[type="submit"] {
    font-size: 1.4rem;
    width: 100%;
    font-weight: 700;
    background-color: #fcad37;
    color: white;
    padding: 14px 20px;
    margin-top: 2%;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    box-shadow: 3px 3px 5px #20201f;
  }
  
  input[type="submit"]:hover {
    transform: scale(1.02);
  }

  @media screen and (max-width: 1300px) {
    .contactTitle {
      font-size: 3.6vw;
    }
    .contactText {
      font-size: 2.4vw;
    }
  }

  @media screen and (max-width: 1000px) {
    .contactTitle {
      font-size: 5vw;
    }
    .contactText {
      font-size: 3.2vw;
    }
  }
.footer {
    display: flex !important;
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;
    padding: 1.5vh;
  }
  .github {
    margin-right: 2%;
    margin-bottom: 2%;
  }
  .linkedin {
    margin-left: 2%;
    margin-bottom: 2%;
  }
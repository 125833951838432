@import url('https://fonts.googleapis.com/css2?family=Dosis&family=Mate+SC&family=Open+Sans&family=Signika+Negative&family=Urbanist:wght@700&display=swap');
* {
  font-family: 'Open Sans', sans-serif;}

body {
  background-color: black;
  width: 100%;
  /* background-image: url('./assets/images/background.jpg');
  background-size: 2100px;
  background-position-y: center; */
  }

  .logo{
    padding: 1%;
    width: 61%;
    /* max-width: 300px; */
    /* margin-left: -15%; */
  }
  
  .navItem {
    font-weight: 700;
    color: rgb(252, 249, 249);
    font-size: 2.5vw;
    /* margin-top: 10%; */
    padding: 5%;
  }
  
  .navItem:hover {
    text-decoration: underline #db2b37;
    text-decoration-thickness: .6vh;
    text-decoration-skip-ink: auto;
    transform: scale(1.1);
    color: #00f6c9;
  }

  @media screen and (max-width: 1300px) {
    .navItem {
      font-size: 3vw;
    }
  }
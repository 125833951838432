.componentContainer {
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 5vh;
  margin-bottom: 5vh;
  padding: 1rem;
  /* border-radius: 30px; */
  background: linear-gradient(to right bottom,
  #b00634,
  #fa6919,
  #d0c500,
  #0bb42f,
  #39bbf5,
  #681d75 
    );
}

.textContainer {
  display: flex !important;
  flex-direction: column;
  align-items: center;
  background-color: #4e565f;
  width: 100%;
  height: 100%;
  /* border-radius: 20px; */
}

.homeTitle {
    color: white;
    text-shadow: 3px 3px 5px #20201f;
    text-align: center;
    font-size: 3vw;
    font-weight: 700;
    margin-top: 1%;
    padding: 3%;
    padding-bottom: 1%;
  }
  
  .homeGreet {
    color: white;
    text-shadow: 3px 3px 5px #20201f;
    font-size: 2vw;
    text-align: center;
  }
  
  .geoFace {
    width: 40%;
    border-radius: 10px;
    box-shadow: 3px 3px 5px 2px black;
    margin: 5%;
  }

  @media screen and (max-width: 1300px) {
    .homeTitle {
      font-size: 3.6vw;
    }
    .homeGreet {
      font-size: 2.4vw;
    }
  }

  @media  all and (max-width: 1000px) {
    .homeTitle {
      font-size: 5vw;
    }

    .homeGreet {
      font-size: 3vw;
    }
  }

  
